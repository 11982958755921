import React, { useState, useLayoutEffect } from "react";

const LangContext = React.createContext({
  lang: '',
  switchLang: () => {},
});

export default LangContext;

export function LangProvider(props) {
  const getValidLang = (lang) => {
    const supportedLangs = ['en-US', 'de-DE'];  
    const langCode = lang.split('-')[0];  
    return supportedLangs.includes(`${langCode}-US`) ? `${langCode}-US` : 'en-US';  
  };

  const [lang, setLang] = useState(getValidLang(window.localStorage.getItem('appUILang') || window.navigator.language));

  useLayoutEffect(() => {
    const selectedLang = window.localStorage.getItem('appUILang');
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);

  const switchLang = (ln) => {
    if (ln !== lang) {
      setLang(ln);
      window.localStorage.setItem('appUILang', ln);
    }
  };

  const langData = {
    'en-US': {
      label: {
        name: 'Name',
      },
      app: {
        p: 'Select Language',
      },
    },
    'de-DE': {
      label: {
        name: 'Name',
      },
      app: {
        p: 'Wähle eine Sprache',
      },
    },
  };

  return (
    <LangContext.Provider value={{
      lang,
      switchLang,
      currentLangData: langData[lang],
    }}>
      {props.children}
    </LangContext.Provider>
  );
}
